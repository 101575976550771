angular
    .module('klaro')
    .directive('boardDirtyMenu', boardDirtyMenu);
function boardDirtyMenu(auth, dimensionRest, dimensionEditModal, navigation) {
    return {
        restrict: 'EA',
        template: require('@/core/board/header/boardDirtyMenu.html'),
        replace: true,
        link: function (scope, elm, attrs) {
        },
    };
}

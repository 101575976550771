angular
    .module('klaro')
    .directive('boardDecksToggle', decksToggle);
function decksToggle(hotkeys) {
    return {
        template: require('@/core/board/decksToggle.html'),
        link: function (scope, elm) {
            const board = elm.closest('.board')[0];
            scope.tooltipText = function () {
                return board.classList.contains('decks-open') ? 'Hide filters' : 'Open filters';
            };
            scope.openFilters = function () {
                elm.parent().addClass('clicked');
                board.classList.add('decks-open');
                board.classList.remove('decks-closed');
            };
            scope.toggleFilters = function () {
                elm.parent().addClass('clicked');
                board.classList.toggle('decks-open');
                board.classList.toggle('decks-closed');
            };
            hotkeys.bindTo(scope)
                .add({
                combo: 'f',
                description: 'Toggle filters',
                callback: function () { scope.toggleFilters(); },
            });
        },
    };
}

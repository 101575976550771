angular
    .module('klaro')
    .service('ActionsForHelper', ActionsForHelper);
function ActionsForHelper($rootScope, dimensionValueEditModal, navigation) {
    const build = function (action, boardCtrl, column) {
        return function (event, params, options) {
            event.preventDefault();
            event.stopPropagation();
            const instance = action(boardCtrl, column);
            if (instance.enabled === undefined || instance.enabled()) {
                return instance.method(event, params, options);
            }
        };
    };
    const newCardAction = function (boardCtrl, column) {
        return {
            label: 'New card',
            enabled: () => boardCtrl.canWrite(),
            method: (_event, _dimensionInfo) => {
                const defaults = column.defaults;
                boardCtrl.openNewStoryModal(defaults);
            },
            param: [column.dimension, column.dimensionValue],
        };
    };
    const editDimensionValueAction = function (boardCtrl, column) {
        return {
            label: 'Rename / Edit …',
            enabled: function () {
                return $rootScope.isAdmin &&
                    column.dimension &&
                    column.dimension.canValuesBeEdited() &&
                    column.dimensionValue.id !== null;
            },
            method: async (event, param, options) => {
                event.preventDefault();
                const dimension = param[0];
                const value = param[1];
                let dimensionValue;
                if (dimension.datatype === 'StoryBinaryLink') {
                    const cardId = value.id;
                    const story = await boardCtrl.openFullEditStoryModal(cardId);
                    dimensionValue = {
                        id: story.identifier,
                        label: story.title,
                        ordering: story.identifier,
                    };
                }
                else {
                    const result = await dimensionValueEditModal
                        .open(dimension, angular.extend({}, value), options)
                        .result;
                    dimensionValue = result.dimensionValue;
                }
                if (dimensionValue) {
                    const originalDim = boardCtrl.getBoardState().dimension(dimension.code);
                    const newValues = originalDim.withChangedValue(dimensionValue, value);
                    boardCtrl.dimensionChanged(newValues);
                }
            },
            param: [column.dimension, column.dimensionValue],
        };
    };
    const deleteValueAction = function (boardCtrl, column) {
        return {
            label: 'Delete value …',
            enabled: function () {
                return $rootScope.isAdmin &&
                    column.dimension &&
                    column.dimension.canValuesBeDeleted() &&
                    column.dimensionValue.id !== null;
            },
            method: ($event, param) => {
                $event.preventDefault();
                const dimension = param[0];
                const value = param[1];
                dimensionValueEditModal
                    .open(dimension, angular.extend({}, value), angular.extend({}, { mode: 'try-delete' }), boardCtrl)
                    .result
                    .then(() => {
                    boardCtrl
                        .refresh()
                        .then(() => boardCtrl.dimensionChanged(dimension.withoutValue(value)));
                });
            },
            param: [column.dimension, column.dimensionValue],
        };
    };
    const makeDimensionRequiredAction = function (boardCtrl, column) {
        return {
            label: 'Forbid No value here',
            enabled: function () {
                return $rootScope.isAdmin &&
                    column.dimensionValue?.id === null &&
                    column.isEmptyCube;
            },
            method: async ($event, params) => {
                await boardCtrl.ensureDimensionRequired(params[0]);
            },
            param: [column.dimension],
        };
    };
    const selectAllCardsAction = function (boardCtrl, column) {
        return {
            label: 'Select all cards',
            enabled: () => boardCtrl.canWrite(),
            method: ($event, _param) => {
                $event.preventDefault();
                // $event.stopPropagation();   // necessary to avoid keeping the menu open...
                boardCtrl.selectSome(column.stories);
            },
            param: [],
        };
    };
    const collapseColumnAction = function (boardCtrl, column) {
        return {
            label: 'Collapse column',
            enabled: () => {
                return boardCtrl.getBoardState().mode === 'kanban';
            },
            method: 'toggleCollapse',
            param: [column.dimension, column.dimensionValue, true],
        };
    };
    const unsortCardsAction = function (boardCtrl, column) {
        return {
            label: 'Move all to Unsorted',
            enabled: () => boardCtrl.canWrite(),
            method: ($event, params) => {
                const storiesIds = params[0].map(s => s.id);
                return boardCtrl.resetAllCasinoCards(storiesIds);
            },
            param: [column.stories],
        };
    };
    const zoomOnCardsAction = function (boardCtrl, column, label = 'Zoom on cards') {
        return {
            label: label,
            icon: 'icon-View-Grid',
            method: (_event, params) => {
                boardCtrl.switchToGridFilteredBy(params[0]);
            },
            param: [column.getFilterPairs()],
        };
    };
    const zoomOnCardsByAction = function (boardCtrl, column, byDimensions, label = 'Zoom on cards by …') {
        return {
            label: label,
            icon: 'icon-View-Kanban',
            children: byDimensions.map((dim) => {
                return {
                    label: dim.label,
                    method: (_event, params) => {
                        boardCtrl.switchToKanbanFilteredBy(params[0], params[1]);
                    },
                    param: [dim, column.getFilterPairs()],
                };
            }),
        };
    };
    const sortCardsAction = function (boardCtrl, column, byDimensions) {
        return {
            label: 'Sort cards by …',
            icon: 'icon-View-Casino',
            children: byDimensions.map((dim) => {
                return {
                    label: dim.label,
                    method: (_event, params) => {
                        boardCtrl.switchToCasinoFilteredBy(params[0], params[1]);
                    },
                    param: [dim, column.getFilterPairs()],
                };
            }),
        };
    };
    const toggleFullScreenMode = function () {
        return {
            label: () => {
                if ($rootScope.fullScreen) {
                    return 'Exit presenter mode';
                }
                else {
                    return 'Presenter Mode';
                }
            },
            icon: 'icon-Board',
            method: (_event, _params) => {
                $rootScope.fullScreen = !$rootScope.fullScreen;
            },
            param: [],
        };
    };
    const columnActions = function (boardCtrl, column) {
        const byDimensions = boardCtrl.getRelevantDimensionsForAnchor('displayBy');
        return [
            newCardAction(boardCtrl, column),
            selectAllCardsAction(boardCtrl, column),
            editDimensionValueAction(boardCtrl, column),
            deleteValueAction(boardCtrl, column),
            {
                separator: true,
            },
            collapseColumnAction(boardCtrl, column),
            makeDimensionRequiredAction(boardCtrl, column),
            {
                separator: true,
            },
            zoomOnCardsAction(boardCtrl, column),
            zoomOnCardsByAction(boardCtrl, column, byDimensions),
            sortCardsAction(boardCtrl, column, byDimensions),
        ];
    };
    const cellActions = function (boardCtrl, column) {
        const byDimensions = boardCtrl.getRelevantDimensionsForAnchor('displayBy');
        return [
            newCardAction(boardCtrl, column),
            selectAllCardsAction(boardCtrl, column),
            {
                separator: true,
            },
            zoomOnCardsAction(boardCtrl, column),
            zoomOnCardsByAction(boardCtrl, column, byDimensions),
            sortCardsAction(boardCtrl, column, byDimensions),
        ];
    };
    const casinoCellActions = function (boardCtrl, column) {
        const byDimensions = boardCtrl.getRelevantDimensionsForAnchor('displayBy');
        return [
            newCardAction(boardCtrl, column),
            selectAllCardsAction(boardCtrl, column),
            editDimensionValueAction(boardCtrl, column),
            deleteValueAction(boardCtrl, column),
            {
                separator: true,
            },
            unsortCardsAction(boardCtrl, column),
            {
                separator: true,
            },
            zoomOnCardsAction(boardCtrl, column),
            zoomOnCardsByAction(boardCtrl, column, byDimensions),
            sortCardsAction(boardCtrl, column, byDimensions),
        ];
    };
    const boardNavigationActions = function (boardCtrl, column) {
        const byDimensions = boardCtrl.getRelevantDimensionsForAnchor('displayBy');
        return [
            newCardAction(boardCtrl, column),
            selectAllCardsAction(boardCtrl, column),
            {
                separator: true,
            },
            zoomOnCardsAction(boardCtrl, column, 'See cards in grid'),
            zoomOnCardsByAction(boardCtrl, column, byDimensions, 'See cards by …'),
            sortCardsAction(boardCtrl, column, byDimensions),
            {
                separator: true,
            },
            toggleFullScreenMode(),
        ];
    };
    return {
        build: build,
        //
        newCardAction: newCardAction,
        editDimensionValueAction: editDimensionValueAction,
        //
        actionsForKanban: columnActions,
        actionsForMatrixColumn: columnActions,
        actionsForMatrixCell: cellActions,
        actionsForBoardNavigation: boardNavigationActions,
        actionsForCasinoCell: casinoCellActions,
    };
}

angular
    .module('klaro')
    .service('MyProjects', MyProjects);
function MyProjects($q, $rootScope, projectRest, navigation) {
    let _refreshing = false;
    let _projects = null;
    function list() {
        if (_refreshing) {
            return _refreshing;
        }
        else if (_projects) {
            const deferred = $q.defer();
            deferred.resolve(_projects);
            return deferred.promise;
        }
        else {
            _refreshing = projectRest.list()
                .then((boards) => {
                _refreshing = false;
                _projects = boards;
                return _projects;
            })
                .catch((err) => {
                _refreshing = false;
                _projects = null;
                navigation.failed(err);
            });
            return _refreshing;
        }
    }
    function onEvent() {
        _projects = null;
        list();
    }
    $rootScope.$on('klaro.project.changed', onEvent);
    $rootScope.$on('klaro.user.changed', onEvent);
    $rootScope.$on('klaro.application.reload', onEvent);
    return {
        list: list,
    };
}

import { LocalStoragePersistence } from '@klaro/corejs/utils';
import { BoardState } from '@klaro/corejs/state';
angular
    .module('klaro.v1')
    .run(['tmhDynamicLocale', 'Project', '$rootScope', function (tmhDynamicLocale, Project, $rootScope) {
        const setLocale = function () {
            Project.get().then((p) => {
                tmhDynamicLocale.set(p.locale || 'en-us');
            });
        };
        setLocale();
        $rootScope.$on('klaro.project.changed', setLocale);
        $rootScope.$on('klaro.user.changed', setLocale);
    }])
    .run(['$rootScope', '$injector', 'navigation', 'hotkeys', function ($rootScope, $injector, navigation, hotkeys) {
        hotkeys
            .bindTo($rootScope)
            .add({
            combo: 'h',
            description: 'Go home',
            callback: function () {
                navigation.gotoHome();
            },
        })
            .add({
            combo: 'p',
            description: 'Presenter mode (no toolbars)',
            callback: function () {
                $rootScope.fullScreen = !$rootScope.fullScreen;
            },
        })
            .add({
            combo: 'ctrl+shift+u',
            callback: function () {
                $injector.get('Perfs').enable().debug();
            },
        });
    }])
    .run(['$rootScope', '$window', '$injector', function ($rootScope, $window, $injector) {
        try {
            // Force a few services to be loaded, since they register
            // on root scope...
            $injector.get('AuthGlobals');
            const chatbot = $injector.get('Chatbot');
            chatbot.install();
            // Check if the accessed route is restricted and redirect the user to the login
            // page when restricted while not logged. The state transition is replayed after
            // a successful login.
            $rootScope.$on('$routeChangeStart', (event, next, current) => {
                if (current && current.$$route.bodyClass) {
                    angular.element('body').removeClass(current.$$route.bodyClass);
                }
                if (next.bodyClass) {
                    angular.element('body').addClass(next.bodyClass);
                }
                if (next.isPublic || next.redirectTo) {
                    return;
                }
                const auth = $injector.get('auth');
                if (!auth.isLogged()) {
                    event.preventDefault();
                    auth.loginPage(true);
                }
            });
            $rootScope.$on('$routeChangeError', (event, next, current, rejection) => {
                console.log('An error occured when changing route:', rejection);
            });
        }
        catch (ex) {
            console.log(ex);
            throw ex;
        }
    }])
    // prevent modal to close when dragging the cursor from inside the modal to outside
    .run(['$rootScope', '$uibModalStack', function ($rootScope, $uibModalStack) {
        $rootScope.$watch(() => {
            return document.querySelectorAll('.modal').length;
        }, (value) => {
            document.querySelectorAll('.modal').forEach((modal) => {
                if ($uibModalStack.getTop() && $uibModalStack.getTop().value.backdrop !== 'static') { // Testing if the modal is supposed to be static before attaching the event
                    modal.addEventListener('mousedown', (e) => {
                        if (e.which === 1) {
                            $uibModalStack.getTop().key.dismiss();
                        }
                    });
                    modal.querySelector('.modal-content').addEventListener('mousedown', (e) => {
                        e.stopPropagation();
                    });
                }
            });
            if (value > 0) {
                $uibModalStack.getTop().value.backdrop = 'static';
            }
        });
    }])
    .run(['$rootScope', function ($rootScope) {
        $rootScope.$watch(() => {
            return document.querySelectorAll('.tooltip').length;
        }, (numberOfVisibleTooltips) => {
            if (numberOfVisibleTooltips > 1) {
                const visibleTooltips = document.querySelectorAll('.tooltip');
                let element, index;
                for (index = 0; index < visibleTooltips.length - 1; index++) {
                    element = visibleTooltips[index];
                    element.classList.add('hidden');
                }
            }
            else if (numberOfVisibleTooltips == 1) {
                document.querySelectorAll('.tooltip')[0].classList.remove('hidden');
            }
        });
    }])
    .run(['$rootScope', '$timeout', function ($rootScope, $timeout) {
        document.body.addEventListener('keydown', (event) => {
            $rootScope.ctrlKeyIsPressed = event.ctrlKey || event.keyCode === 17;
            $rootScope.$digest();
        });
        document.body.addEventListener('keyup', (event) => {
            $rootScope.ctrlKeyIsPressed = false;
            $rootScope.$digest();
        });
    }])
    .run(() => {
    BoardState.setPersistence(new LocalStoragePersistence());
})
    .run(['$templateCache', ($templateCache) => {
        $templateCache.put('@/core/story/attachments-gallery.html', require('@/core/story/attachments-gallery.html'));
    }])
    .run(['$location', '$rootScope', ($location, $rootScope) => {
        const search = $location.search();
        const zoom = search._z || search._zoom || search._p;
        if (zoom && zoom.match(/^\d+\.\d+$/)) {
            document.body.style.zoom = `${zoom * 100}%`;
        }
        if (search._presenterMode || search._p) {
            $rootScope.fullScreen = true;
        }
    }]);

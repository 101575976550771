import { Project } from '@klaro/corejs/model';
angular
    .module('klaro.kernel')
    .service('KernelProject', KernelProject);
/**
 * This service encapsulates the kernel logic of loading the current project
 * through a backend call and handling any error response.
 *
 * It is aimed at being `init`ed *before* starting the application, since the
 * initialization process requires an async call to the backend.
 */
function KernelProject($http, $log, $timeout, $window, KernelConstants, KernelAuth) {
    /**
     * Loads the current project using the public backend API.
     *
     * If the project is unknown (404 + Location header), makes
     * a hard redirect through `window.location =`.
     *
     * Otherwise, save the project info on kernel constants and
     * return it.
     */
    function init(detached = false) {
        $log.info('Loading the current project...');
        function onProjectError(err) {
            $log.error(err);
            if (err.status == 404) {
                const location = err.headers('Location');
                if (location) {
                    $timeout(() => {
                        window.location = location;
                    }, 500);
                    throw new Error('This project does not exist yet. Let me help you');
                }
            }
            throw new Error('Unable to load project information. Please contact us');
        }
        const setProject = function (data) {
            // Set the project
            $log.debug(data);
            KernelConstants.project = Project.dress(data);
            // Infer rootDomain so that services that need it can find it.
            // The logic below takes care of stripping the subdomain, if any
            // (since the master admin project has an empty subdomain)
            const subdomain = data.subdomain;
            const trimLength = subdomain.length == 0 ? 0 : 1 + subdomain.length;
            const hostname = $window.location.hostname;
            KernelConstants.rootDomain = hostname.substring(trimLength);
            return KernelConstants.project;
        };
        if (detached) {
            return Promise.resolve(setProject({
                id: 'whiteboard',
                name: 'Whiteboard',
                subdomain: 'whiteboard',
                isPublic: true,
                logoUrl: '',
            }));
        }
        else {
            return $http
                .get('/api/', KernelAuth.getAuthorizationOptions())
                .then((result) => setProject(result.data))
                .catch(onProjectError);
        }
    }
    // This is the service API
    return {
        init: init,
    };
}

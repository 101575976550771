angular
    .module('klaro')
    .directive('boardDecks', boardDecks);
function boardDecks() {
    return {
        restrict: 'E',
        template: require('@/core/board/boardDecks.html'),
        require: '^board',
        scope: true,
        link: function (scope, elm, attrs, board) {
            scope.boardFiltersOnLoad = function () {
                board
                    .toggleDecks();
            };
            scope.deckDimensions = [];
            scope.openDimension = null;
            scope.clearFilters = board.clearFilters.bind(board);
            scope.$watch('board', () => {
                scope.deckDimensions = board.getDeckDimensions();
                scope.allOpen = scope.deckDimensions.length <= 3;
                scope.openDimension = scope.deckDimensions.find((d) => {
                    return !board.hasFilter(d);
                });
            });
            scope.isOpen = function (dim) {
                return scope.allOpen || scope.openDimension && scope.openDimension === dim;
            };
            scope.isCompact = function () {
                return board.isCompactDecks();
            };
            scope.toggleDecksCompactness = function () {
                board.toggleDeckCompactness();
            };
            scope.toggleDecksExplorer = function () {
                board.toggleDecksExplorer();
            };
            scope.isExplorerDecks = function () {
                return board.isExplorerDecks();
            };
            scope.filtersCount = function () {
                const filters = board.visibleFilters();
                return filters.size();
            };
            scope.filtersCountText = function () {
                const filtersCount = scope.filtersCount();
                return filtersCount > 1 ?
                    `${filtersCount} FILTERS` :
                    `${filtersCount} FILTER`;
            };
            scope.filterVisible = function () {
                return board.isDecksOpenOnLoad();
            };
            scope.showDimensionDecks = function () {
                const board = elm.closest('.board')[0];
                return board.classList.contains('decks-open');
            };
        },
    };
}

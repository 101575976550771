angular
    .module('klaro')
    .service('dimensionValueEditModal', dimensionValueEditModal)
    .controller('DimensionValueEditModalController', DimensionValueEditModalController);
function dimensionValueEditModal(klaroModal) {
    return {
        open: function (dimension, formData, options) {
            return klaroModal.open({
                animation: false,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('@/core/dimension/dimensionValueEditModal.html'),
                controller: 'DimensionValueEditModalController',
                windowClass: 'klaro-modal center-modal',
                size: 'small',
                resolve: {
                    dimension: function () {
                        return dimension;
                    },
                    formData: function () {
                        return formData;
                    },
                    options: function () {
                        return options || {};
                    },
                },
            });
        },
    };
}
function DimensionValueEditModalController($scope, $uibModalInstance, dimensionRest, dimension, formData, options, robust, Colors, navigation, storyRest) {
    $scope.dimension = dimension;
    $scope.formData = formData;
    $scope.deleteData = { 'onExisting': 'fail' };
    $scope.mode = options.mode || 'edit';
    $scope.replacingDimension = dimension.withoutValue(formData);
    $scope.cardColors = Colors.cards();
    $scope.isNew = !$scope.formData.id;
    $scope.title = $scope.isNew ? 'New value' : 'Edit value';
    $scope.hasDescription = $scope.formData.description;
    $scope.hasPlaceholder = $scope.formData.placeholder;
    $scope.focusOn = function (field) {
        return field === (options.focusOn || 'label');
    };
    $scope.cancel = function () {
        $uibModalInstance.dismiss();
    };
    $scope.commit = function (dimensionValue) {
        $uibModalInstance.close(dimensionValue);
    };
    $scope.semanticsDocHref = function () {
        const cards = {
            DateRange: '273',
        };
        const card = cards[$scope.dimension.semanticsType];
        if (!card) {
            return false;
        }
        return `https://faq.klaro.cards/stories/${card}`;
    };
    $scope.semanticsLabel = function () {
        const labels = {
            Integer: 'Number',
            DateRange: 'Date interval',
            IntegerRange: 'Number interval',
        };
        return labels[$scope.dimension.semanticsType] || 'Semantics';
    };
    $scope.save = function () {
        $scope.error = null;
        dimensionRest
            .saveValue(dimension, $scope.formData)
            .then((dimensionValue) => {
            $scope.commit({
                event: $scope.formData.id ? 'created' : 'updated',
                dimensionValue: dimensionValue,
                dimension: $scope.replacingDimension.addValue(dimensionValue),
            });
        })
            .catch((err) => {
            $scope.error = robust.message(err.data);
        });
    };
    $scope.tryDelete = function () {
        return deleter()
            .catch(() => {
            $scope.deleteData = { 'onExisting': 'replace' };
            $scope.mode = 'confirm-delete';
        });
    };
    $scope.moveToSourceToRemove = function () {
        const url = `/settings/${$scope.dimension.getValueDeletionPlace()}/`;
        navigation.gotoUrl(url, {
            q: $scope.formData.label,
        });
        $scope.cancel();
    };
    $scope.canDelete = function () {
        return ($scope.deleteData.onExisting !== 'replace')
            || !$scope.dimension.required
            || $scope.deleteData.replaceBy;
    };
    $scope.delete = function () {
        return deleter()
            .catch(navigation.failed);
    };
    $scope.$watch('deleteData.replaceBy', (newV) => {
        if ($scope.deleteData.onExisting !== 'fail') {
            if (newV === null) {
                $scope.deleteData.onExisting = 'setNone';
            }
            else {
                $scope.deleteData.onExisting = 'replace';
            }
        }
    });
    function deleter() {
        if (dimension.datatype === 'StoryBinaryLink') {
            return storyRest
                .del($scope.formData, $scope.deleteData)
                .then(() => {
                $scope.commit({
                    event: 'deleted',
                    dimensionValue: $scope.formData,
                    dimension: $scope.replacingDimension,
                });
            });
        }
        else {
            return dimensionRest
                .deleteValue(dimension, $scope.formData, $scope.deleteData)
                .then(() => {
                $scope.commit({
                    event: 'deleted',
                    dimensionValue: $scope.formData,
                    dimension: $scope.replacingDimension,
                });
            });
        }
    }
    $scope.showDescriptionTextarea = function () {
        $scope.hasDescription = !$scope.hasDescription;
    };
    $scope.showPlaceholderTextarea = function () {
        $scope.hasPlaceholder = !$scope.hasPlaceholder;
    };
    if (options.mode && options.mode === 'try-delete') {
        $scope.tryDelete();
    }
}

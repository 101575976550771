import Dimension from '../Dimension';
class Title extends Dimension {
    get category() {
        return 'system';
    }
    getValues(_) {
        return [];
    }
    matchingValuesOf(_story, _globalContext) {
        return [];
    }
    isPhantomDimension() {
        return true;
    }
    canBeUsedOnDecks() {
        return false;
    }
    canBeUsedOnCards() {
        return false;
    }
    canBeUsedAsDateBoundary() {
        return false;
    }
    canBeEdited() {
        return true;
    }
    canBeEditedAsCardDimension() {
        return false;
    }
    canBeUsedForOrdering() {
        return true;
    }
    canValuesBeEdited() {
        return false;
    }
    participatesToTextSearch() {
        return true;
    }
    searchMatchesOn(search, regexp, story, _globalContext) {
        const title = this.getStoryRawValue(story);
        return title && title.match(regexp);
    }
}
export default Title;

angular
    .module('klaro')
    .service('MyBoards', MyBoards);
function MyBoards($q, $rootScope, boardRest, navigation, auth, $filter) {
    let _refreshing = false;
    let _boards = null;
    function list() {
        if (_refreshing) {
            return _refreshing;
        }
        else if (_boards) {
            const deferred = $q.defer();
            deferred.resolve(_boards);
            return deferred.promise;
        }
        else {
            _refreshing = boardRest.list()
                .then((boards) => {
                _refreshing = false;
                _boards = $filter('orderBy')(boards, '+ordering');
                return _boards;
            })
                .catch((err) => {
                _refreshing = false;
                _boards = null;
                navigation.failed(err);
            });
            return _refreshing;
        }
    }
    function inCurrentWorkspace() {
        return list()
            .then((boards) => {
            const viewAs = auth.getViewAs() && auth.getViewAs().code;
            return boards.filter((board) => {
                const workspaces = board.workspacePermissions.getWorkspaceCodes();
                return workspaces.indexOf(viewAs) > -1;
            });
        });
    }
    function defaultOne() {
        return list()
            .then((boards) => {
            const user = auth.getUser();
            const homeBoard = user && user.preferences && user.preferences.homeBoard;
            const board = boards.find(b => b.location === homeBoard);
            if (board) {
                return board;
            }
            else if (!auth.isLogged() || !auth.isProjectUser()) {
                return boards[0];
            }
        });
    }
    function onEvent() {
        _boards = null;
        list();
    }
    $rootScope.$on('klaro.user.changed', onEvent);
    $rootScope.$on('klaro.project.changed', onEvent);
    $rootScope.$on('klaro.application.reload', onEvent);
    $rootScope.$on('klaro.board.changed', onEvent);
    $rootScope.$on('klaro.boards.changed', onEvent);
    return {
        list: list,
        defaultOne: defaultOne,
        inCurrentWorkspace: inCurrentWorkspace,
    };
}

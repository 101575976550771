angular
    .module('klaro')
    .directive('storyCard', storyCard);
function storyCard(attachmentsService, storyCardActions) {
    return {
        restrict: 'E',
        template: require('@/core/story/storyCard.html'),
        scope: {
            'story': '=',
            'type': '=',
            'currentIndex': '=?',
        },
        require: ['^board', '?^newStoryHover'],
        link: function (scope, elm, attrs, controllers) {
            const noCoverImage = (attrs.noCoverImage !== undefined);
            const noDimensions = (attrs.noDimensions !== undefined);
            const board = controllers[0];
            const newStoryHover = controllers[1];
            scope.inBoard = board;
            // Let newStoryHover know that we are on a card, so that it can
            // stop highlighting the new card link. And stop propagation so
            // that event does not end up being considered by the container
            // itself.
            elm.on('mouseover', (event) => {
                if (!newStoryHover) {
                    return;
                }
                newStoryHover.dropHover();
                event.stopPropagation();
            });
            scope.onDrop = function (targetDim, targetValue, targetIndex, dropCallback = null) {
                if (dropCallback) {
                    // Drop behavior is handled by the drop place itself
                    if (board.hasStorySelection()) {
                        dropCallback(board.selectedStoriesIds());
                    }
                    else {
                        dropCallback([scope.story.id]);
                    }
                }
                else if (board.hasStorySelection()) {
                    // moving story selection within grid/list can be ignored
                    // (unless drop occurs on filters)
                    if (!targetDim || !targetValue) {
                        return;
                    }
                    board.moveSelectionTo(targetDim, targetValue);
                }
                else if (targetDim && targetValue) {
                    // moving story to filters or a kanban column
                    const hasCurrentIndex = (scope.currentIndex !== undefined);
                    if (hasCurrentIndex) {
                        const globalContext = board.getGlobalContext();
                        const noValueChange = scope.story.hasValue(targetDim, targetValue, globalContext);
                        let tIndex = targetIndex;
                        if (noValueChange) {
                            // ignore dnd's placeholder
                            tIndex = (tIndex > scope.currentIndex ? tIndex - 1 : tIndex);
                            // prevent installing custom order if unnecessary
                            tIndex = (tIndex === scope.currentIndex ? undefined : tIndex);
                        }
                        board.moveStoryTo(scope.story, targetDim, targetValue, tIndex);
                    }
                    else {
                        // Drop to filters: don't try to move with custom order
                        board.moveStoryTo(scope.story, targetDim, targetValue);
                    }
                }
                else {
                    // moving story withing grid or list
                    board.moveStoryInCustomOrder(scope.story, targetIndex);
                }
            };
            scope.highlight = function () {
                board.highlight(scope.story);
            };
            scope.unhighlight = function () {
                board.unhighlight();
            };
            scope.toggleStorySelection = function (event) {
                event.target.blur();
                return board.toggleStorySelection(scope.story);
            };
            scope.$watch('story', (s) => {
                if (!s) {
                    return;
                }
                computeScopeCache(s);
            });
            board.$watch('board', () => {
                computeScopeCache(scope.story);
            }, scope);
            function computeScopeCache(s) {
                scope.storyCssClasses = getStoryCssClasses(s);
                scope.showCoverImage = !noCoverImage && getShowCoverImage(s);
                scope.coverImageUrl = getCoverImageUrl(s);
                scope.cardDimensions = noDimensions ? [] : getCardDimensions(s);
                scope.showCoverImageAsImg = !board.getBoardState().isMode('gallery');
                scope.selectedStory = board.isStorySelected(s);
                scope.displayNoneOnCards = board.getBoardState().displayNoneOnCards;
            }
            function getStoryCssClasses(story) {
                return [
                    'story-card',
                    board.getStoryColorClass(story),
                    board.isStorySelected(story) ? 'checked' : '',
                ].join(' ');
            }
            function getShowCoverImage(story) {
                const theBoard = board.getBoardState();
                // The board shows images and we have one for the story
                return theBoard.isMode('gallery') || (theBoard.showCoverImage() && getCoverImageUrl(story));
            }
            function getCoverImageUrl(story) {
                const url = story.getCoverImageUrl(board.getBoardState());
                return url;
            }
            function getCardDimensions(story) {
                return board.getCardDimensionsFor(story);
            }
            scope.fileUploaded = function (promise) {
                return attachmentsService.fileUploadHandler(scope.inBoard, scope.story, promise);
            };
            scope.openCardActionsMenu = function (e) {
                storyCardActions
                    .open(scope.inBoard, scope.story, e.target);
            };
        },
    };
}

angular
    .module('klaro.kernel', [
    'tmh.dynamicLocale',
])
    .config(['$logProvider', function ($logProvider) {
        $logProvider.debugEnabled(true);
    }])
    .constant('KernelConstants', {})
    .factory('Kernel', Kernel);
/**
 * Tiny Kernel module, in charge of initializing everything before actually
 * starting the application. Indeed, the initialization process requires a
 * few asynchronous calls, that need to be finished before angular routing
 * starts.
 *
 * The actual initialization job is done by the various KernelXXX services.
 * Please refer to them. The order in which those modules are initialized
 * is hardcoded here, though.
 *
 * This service is aimed at being used in the root .html file, with a manual
 * bootstrapping process. Typically something like the following code (replace
 * 'xxx' by your own application module):
 *
 * ```
 * angular.element(document).ready(function() {
 *   var $injector = angular.injector(['ng','klaro.kernel']);
 *   $injector
 *     .get('Kernel')
 *     .init()
 *     .then(function() {
 *       angular.bootstrap(document, ['xxx'], { strictDi: true });
 *     })
 *     .catch(function() {
 *       // User-friendly error handling should be done here, the Kernel
 *       // may refuse to load for some reason...
 *     })
 *   ;
 * });
 * ```
 */
function Kernel($q, $log, KernelConstants, KernelSession, KernelProject, KernelAuth) {
    /**
     * Starts the initialization process.
     */
    function init(detached = false) {
        $log.info('Klaro kernel initializing...');
        return start()
            .then(() => KernelSession.init(detached))
            .then(() => KernelAuth.init(detached))
            .then(() => KernelProject.init(detached))
            .then(done);
    }
    function start() {
        // For the animation...
        const deferred = $q.defer();
        deferred.resolve();
        return deferred.promise;
    }
    function done() {
        $log.info('Kernel initialized without error.');
        $log.debug(KernelConstants);
        return KernelConstants;
    }
    function onError(err) {
        $log.error('Unable to load the Kernel');
        $log.error(err);
    }
    return {
        init: init,
    };
}

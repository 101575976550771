angular
    .module('klaro')
    .directive('boardAnchorApply', boardAnchorApply);
function boardAnchorApply(auth, dimensionRest, dimensionEditModal, navigation) {
    return {
        restrict: 'EA',
        template: require('@/core/board/boardAnchorApply.html'),
        require: '^board',
        scope: true,
        link: function (scope, elm, attrs, board) {
            const anchor = attrs.anchor;
            scope.labelText = attrs.labelText;
            board.$watch('board', (newBoard) => {
                if (!newBoard) {
                    return;
                }
                scope.selected = board.getDimensionsAt(anchor)[0];
            }, scope);
            scope.dimensions = [];
            board.$watch('board', () => {
                scope.dimensions = board.getRelevantDimensionsForAnchor(anchor);
                if (auth.isOwner()) {
                    scope.dimensions.push({ code: '+', label: '+ Create dimension' });
                }
                if (attrs.none) {
                    scope.dimensions.push({ code: '-', label: '[none]' });
                }
                scope.dimensions = scope.dimensions.sort((x, y) => {
                    if (x.code == '+') {
                        return 1;
                    }
                    else if (x.code == '-') {
                        return -1;
                    }
                    else if (x.label < y.label) {
                        return -1;
                    }
                    else {
                        return 1;
                    }
                });
            }, scope);
            scope.$watch('selected', (newDim, oldDim) => {
                if (newDim && newDim.code === '+') {
                    const onDimensionAdded = function (d) {
                        return board.addNewDimension(d).then(() => {
                            board.setDimensionsAt(anchor, [d]);
                        });
                    };
                    dimensionEditModal
                        .open(dimensionRest.newOne())
                        .result
                        .then(onDimensionAdded);
                }
                else {
                    let shouldBe = null;
                    if (newDim && newDim.code === '-') {
                        shouldBe = [];
                    }
                    else if (newDim) {
                        shouldBe = [newDim];
                    }
                    else if (oldDim) {
                        shouldBe = [];
                    }
                    if (shouldBe !== null && !board.hasDimensionsAt(anchor, shouldBe)) {
                        board.setDimensionsAt(anchor, shouldBe);
                    }
                }
            });
        },
    };
}

import { Session } from '@klaro/corejs/model';
angular
    .module('klaro.kernel')
    .service('KernelSession', KernelSession);
/**
 * This service encapsulates the kernel logic of loading the current project
 * through a backend call and handling any error response.
 *
 * It is aimed at being `init`ed *before* starting the application, since the
 * initialization process requires an async call to the backend.
 */
function KernelSession($log, KernelConstants) {
    function newSession() {
        return new Session({});
    }
    function init(detached = false) {
        $log.info('Loading the current project...');
        KernelConstants.session = newSession();
        return Promise.resolve(KernelConstants.session);
    }
    function get() {
        return KernelConstants.session;
    }
    // This is the service API
    return {
        init: init,
        get: get,
    };
}

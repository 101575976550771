import { texter } from '@klaro/ui/src/composables/StoryDimensionValue';
angular
    .module('klaro')
    .directive('storyGantt', storyGantt);
function storyGantt(dimensionEditModal, dimensionRest, tmhDynamicLocale, navigation, storyCardActions, $document) {
    return {
        restrict: 'E',
        template: require('@/core/story/storyGantt.html'),
        scope: {
            'onClick': '&',
            'canWrite': '=',
        },
        require: '^board',
        link: function (scope, elm, attrs, board) {
            let ganttDimensions;
            function computeGantt() {
                scope.gantt = board.getBoardState().ganttView;
                scope.canBeEdited = board.canWrite();
                if (scope.gantt && scope.gantt.isConfigured()) {
                    ganttDimensions = [
                        board.dimension(scope.gantt.getStartDateDimension()),
                        board.dimension((scope.gantt.getStopDateDimension() === undefined) ? scope.gantt.getStartDateDimension() : scope.gantt.getStopDateDimension()),
                    ];
                }
                scope.selectedStories = board.selectedStoriesIds().reduce((a, v) => ({ ...a, [v]: true }), {});
            }
            board.$watch('board', computeGantt, scope);
            scope.displayDate = function (story, index) {
                const dim = ganttDimensions[index];
                const value = dndView ? dndView.interval[index].toISO() : story[dim.code];
                const locale = tmhDynamicLocale.get();
                const valueStr = texter(dim, value, locale);
                return `${dim.label}<br>${valueStr}`;
            };
            scope.getStoryColorClass = function (story) {
                return board.getStoryColorClass(story) || 'no-color';
            };
            scope.isStorySelected = function (story) {
                return board.isStorySelected(story);
            };
            scope.toggleStorySelection = function (story, $event) {
                $event.target.blur();
                return board.toggleStorySelection(story);
            };
            let posX;
            function getTimepointFor(event) {
                posX = event.clientX || posX;
                const posY = elm.find('.story-gantt .timepoints')[0].getBoundingClientRect().top + 30;
                const timepoint = $document[0].elementFromPoint(posX, posY);
                if (timepoint.classList.contains('timepoints')) {
                    const index = parseInt(timepoint.dataset.index);
                    return scope.gantt.timepoints()[index];
                }
            }
            let dndHandler, dndView, dndMouseEvent;
            function resetDnd() {
                dndHandler = null;
                dndView = null;
                dndMouseEvent = $document[0].createEvent('MouseEvent');
            }
            resetDnd();
            function refreshDndView(event) {
                if (dndView) {
                    const uiState = dndHandler.uiStateFor(dndView, dndOptions(event));
                    uiState.target.style['gridColumnStart'] = dndView.gridColumnStart;
                    uiState.target.style['gridColumnEnd'] = dndView.gridColumnEnd;
                    for (const k in dndView.cssClasses) {
                        const v = dndView.cssClasses[k] ? 'add' : 'remove';
                        uiState.target.classList[v](k);
                    }
                    const fillDate = uiState.target.querySelector('.fill-date');
                    if (fillDate) {
                        fillDate.style['display'] = 'none';
                    }
                    dndMouseEvent.initMouseEvent('mouseover');
                    uiState.tooltips.forEach((t) => {
                        t.dispatchEvent(dndMouseEvent);
                    });
                }
            }
            function dndOptions(event) {
                return { ctrl: event.ctrlKey };
            }
            scope.onStoryClick = function (event, storyView) {
                const openStory = function () {
                    return scope.onClick({ story: storyView.story, $event: event });
                };
                const hasStop = scope.gantt.hasStopDateDimension();
                const canEdit = scope.gantt.canChangeInstalledStoryDates(scope.canBeEdited);
                if (!canEdit) {
                    return openStory();
                }
                else if (storyView.hasBar) {
                    return openStory();
                }
                else if (hasStop) {
                    return openStory();
                }
                else {
                    const story = storyView.story;
                    const timepoint = getTimepointFor(event);
                    const newStoryData = story.toRaw();
                    const startCode = scope.gantt.getStartDateDimension().code;
                    newStoryData[startCode] = timepoint.toFormat('yyyy-MM-dd');
                    saveStory(story, newStoryData);
                }
            };
            scope.onStoryDragStart = function (event, storyView, which) {
                if (dndHandler) {
                    return;
                }
                const timepoint = getTimepointFor(event);
                if (timepoint) {
                    let uiState;
                    switch (which) {
                        case 'whole':
                            uiState = {
                                target: event.target,
                                tooltips: event.target.querySelectorAll('.gantt-date'),
                            };
                            break;
                        case 'start':
                        case 'stop':
                            uiState = {
                                target: event.target.parentElement,
                                tooltips: [event.target],
                            };
                    }
                    dndHandler = scope.gantt.dndStart(which, storyView, () => {
                        return uiState;
                    });
                    dndView = dndHandler.dragStart(timepoint, dndOptions(event));
                    refreshDndView(event);
                }
                return true;
            };
            scope.onStoryDragging = function (event) {
                const timepoint = getTimepointFor(event);
                if (dndHandler && timepoint) {
                    dndView = dndHandler.dragging(timepoint, dndOptions(event));
                    refreshDndView(event);
                }
            };
            scope.onStoryDragEnd = function (event) {
                const timepoint = getTimepointFor(event);
                if (dndHandler && timepoint) {
                    dndView = dndHandler.drop(timepoint, dndOptions(event));
                    refreshDndView(event);
                    commitDndView();
                }
            };
            function commitDndView() {
                const newStoryData = dndView.story.toRaw();
                const startCode = scope.gantt.getStartDateDimension().code;
                const endCode = scope.gantt.getStopDateDimension() && scope.gantt.getStopDateDimension().code;
                newStoryData[startCode] = dndView.interval[0].toFormat('yyyy-MM-dd');
                if (endCode) {
                    newStoryData[endCode] = dndView.interval[1].toFormat('yyyy-MM-dd');
                }
                saveStory(dndView.story, newStoryData);
            }
            function saveStory(oldStory, newStoryData) {
                const newStory = board.newStory(newStoryData);
                board
                    .saveStory(newStory, oldStory)
                    .then(resetDnd)
                    .catch(navigation.failed);
            }
            scope.noBarText = function (canBeEdited) {
                if (!scope.gantt.canChangeInstalledStoryDates(canBeEdited)) {
                    return 'No dates on this card';
                }
                else if (scope.gantt.hasStopDateDimension()) {
                    return 'Drag & drop to set the card dates';
                }
                else {
                    return 'Click a cell to set a card date';
                }
            };
            /**
             * Configure Gantt
             * When the start and end dates are not selected
             */
            function getDateDimensions() {
                scope.dateDimensions = board.getDateBoundaryDimensions();
                scope.dateDimensions = scope.dateDimensions.sort((x, y) => {
                    if (x.label < y.label) {
                        return -1;
                    }
                    else {
                        return 1;
                    }
                });
            }
            getDateDimensions();
            scope.ganttDates = {
                ganttStarts: (scope.dateDimensions.length) ? scope.dateDimensions[0] : null,
            };
            scope.newDateDimension = function (ganttDate) {
                const installersFilter = function (i) {
                    return i.canBeUsedAsDateBoundary();
                };
                dimensionEditModal.open(dimensionRest.newOne('Date'), null, installersFilter)
                    .result
                    .then((dimension) => {
                    return board.addNewDimension(dimension).then(() => {
                        getDateDimensions();
                        scope.ganttDates[ganttDate] = dimension;
                    });
                });
            };
            scope.configureGantt = function () {
                board
                    .switchToGantt(null, [scope.ganttDates.ganttStarts, scope.ganttDates.ganttStops]);
            };
            scope.hideEmptyCategories = function () {
                board.setCompactDisplay(true);
            };
            /** Summaries */
            scope.summariesDropdown = {
                show: false,
                element: null,
            };
            scope.toggleSummariesMenu = function ($event) {
                scope.summariesDropdown = {
                    show: !scope.summariesDropdown.show,
                    element: $event.target.closest('.summary-cell'),
                };
            };
            scope.openCardActionsMenu = function (e, story, showSelectionCheckboxes) {
                storyCardActions
                    .open(board, story, e.target, showSelectionCheckboxes);
            };
        },
    };
}

angular
    .module('klaro.v1')
    .controller('HomeController', HomeController);
function HomeController($log, Project, navigation, MyBoards, auth) {
    Project.get().then((project) => {
        $log.debug('Applying home logic on:', project);
        auth.resetViewAs();
        // the === null is wanted, it indicates that the template
        // entry is there (so user is logged), but no template is set
        if (project.isLocked === false && !project.isTemplate && project.template === null) {
            navigation.gotoUrl('/onboard/');
        }
        else {
            MyBoards.defaultOne().then((board) => {
                if (board) {
                    navigation.gotoUrl(`/boards/${board.location}`);
                }
                else if (auth.isLogged() && auth.isProjectUser()) {
                    navigation.gotoUrl('/dashboard');
                }
                else {
                    navigation.gotoUrl('/auth/login');
                }
            });
        }
    });
}

angular
    .module('klaro')
    .directive('dimensionRow', dimensionRow);
function dimensionRow(dimensionValueEditModal, navigation, dimensionRest, ArrayUtils, $rootScope) {
    return {
        restrict: 'A',
        template: require('@/core/dimension/dimensionList/dimensionRow.html'),
        scope: {
            dimension: '=dimensionRow',
            onClick: '&',
            onDimensionDrop: '&',
        },
        replace: true,
        require: '^dimensionList',
        link: function (scope, elm, attrs, dimensionList) {
            function notifyChange(dimension) {
                dimensionList.dimensionChanged(dimension);
            }
            scope.displayValues = function () {
                return scope.dimension.datatype !== 'StoryBinaryLink';
            };
            scope.onDimensionValueDrop = function (index, dimension, dimValue) {
                const patchIt = function (item, ordering) {
                    const patch = { ordering: ordering };
                    return dimensionRest.patchValue(dimension, item, patch);
                };
                ArrayUtils.moveInOrdering(dimension.getValues(false), dimValue, index, patchIt)
                    .then((newValues) => {
                    notifyChange(dimension.withValues(newValues));
                })
                    .catch(navigation.failed);
            };
            scope.newDimensionValue = function (dimension, $event) {
                $event.preventDefault();
                $event.stopPropagation();
                if (!$rootScope.isAdmin) {
                    return;
                }
                openDimensionValueModal(dimension, dimensionRest.newValueData(dimension));
            };
            scope.editDimensionValue = function (dimension, value, $event) {
                $event.preventDefault();
                $event.stopPropagation();
                if (!$rootScope.isAdmin) {
                    return;
                }
                if (!dimension.deleted) {
                    openDimensionValueModal(dimension, angular.extend({}, value));
                }
            };
            function openDimensionValueModal(dimension, formData) {
                dimensionValueEditModal
                    .open(dimension, formData)
                    .result
                    .then((evt) => {
                    notifyChange(evt.dimension);
                });
            }
            scope.orderAlphabetically = function ($event, dimension) {
                $event.preventDefault();
                $event.stopPropagation();
                dimensionRest
                    .orderAlphabetically(dimension)
                    .then((dimWithOrderedValues) => {
                    notifyChange(dimWithOrderedValues);
                });
            };
            scope.editDimension = function ($event, dimension) {
                $event.stopPropagation();
                scope.onClick({ dimension: dimension });
            };
            scope.supportsAlphabeticalOrdering = function (dimension) {
                return dimension.supportsAlphabeticalOrdering;
            };
            scope.canAddDimensionValue = function (dimension) {
                if (dimension.deleted) {
                    return false;
                }
                // Adding values to the kind dimension is always authorized
                // in the dimension screen, even if the dimension is locked
                return (dimension.tagging || dimension.datatype === 'Kind');
            };
        },
    };
}

import { Story } from '@klaro/corejs/model';
angular
    .module('klaro')
    .directive('linkedCards', linkedCards);
function linkedCards(dimensionEditModal, dimensionRest, cardAppearanceModal, storyRest, navigation, confirm) {
    return {
        restrict: 'E',
        template: require('@/core/story/linkedCards.html'),
        scope: {
            inBoard: '=',
            story: '=',
            storyData: '=',
            dimensions: '=',
            readOnly: '=',
            onReloadRequest: '&',
            linkedCards: '=',
        },
        link: function (scope) {
            scope.openStory = function (story, $event) {
                $event.stopPropagation();
                $event.preventDefault();
                scope.inBoard
                    .openFullEditStoryModal(story.identifier)
                    .then(storyChanged);
            };
            scope.openCardAppearanceModal = function ($event, story) {
                $event.stopPropagation();
                $event.preventDefault();
                if (!scope.linkedCards) {
                    return;
                }
                function refreshKindDimension(arg) {
                    const kindDim = scope.linkedCards.kindDim.withAppearanceFor(arg.kind, {
                        summaryDimensions: arg.summaryDimensions,
                        colorDimension: arg.colorDimension,
                    });
                    storyChanged();
                    scope.inBoard.dimensionChanged(kindDim);
                    return arg;
                }
                const kind = scope.linkedCards.storyKind(story);
                const showDims = scope.linkedCards.shownDimensions(story);
                const colorDim = scope.linkedCards.colorDimOf(story);
                cardAppearanceModal
                    .open(scope.inBoard, scope.dimensions, kind, showDims, colorDim)
                    .result
                    .then(refreshKindDimension);
            };
            scope.colorOf = function (story) {
                if (!scope.linkedCards) {
                    return;
                }
                const globalContext = scope.inBoard.getGlobalContext();
                return scope.linkedCards.colorOf(story, globalContext);
            };
            scope.linkCard = function (section) {
                scope.inBoard
                    .openLinkStoryModal(section.dimension, scope.story)
                    .then(storyChanged);
            };
            scope.unlinkCard = function ($event, link) {
                $event.stopPropagation();
                $event.preventDefault();
                confirm({
                    h2: 'Unlink child card',
                    hint: 'Are you sure you want to unlink this child card?',
                }, () => {
                    storyRest
                        .unlinkStories({
                        code: link.dimension.code,
                        children: [link.story.identifier],
                        parent: scope.story.identifier,
                    })
                        .then((stories) => {
                        storyChanged();
                        scope.inBoard.storiesChanged(stories.map(s => Story.dress(s, { dimensions: scope.dimensions })));
                    });
                });
            };
            scope.createLink = function ($event) {
                if (!scope.linkedCards) {
                    return;
                }
                $event.stopPropagation();
                $event.preventDefault();
                const kindDim = scope.linkedCards.kindDim;
                const newDim = dimensionRest.newOne('StoryBinaryLink', {
                    datatypeOptions: {
                        kind: kindDim.getStoryRawValue(scope.story),
                    },
                });
                dimensionEditModal
                    .open(newDim)
                    .result
                    .then((d) => {
                    scope.inBoard.addNewDimension(d);
                });
            };
            function storyChanged() {
                scope.onReloadRequest();
            }
        },
    };
}

import { Filters } from '@klaro/corejs/model';
import { AngularRestHandler } from '@klaro/corejs/rest';
import { BoardState } from '@klaro/corejs/state';
import { BoardSyncModes, withMode } from '@klaro/corejs/state/sync';
export function boardResolver(which, mode, callback) {
    return async function ($route, $location, $http, auth, klaroUi, MyPinnedStories) {
        try {
            const boardId = which ?? ($route.current.params.id ?? 'all');
            const url = `/api/boards/${boardId}`;
            const search = $location.search();
            const canPrefetch = (mode !== 'dashboard' && Object.keys(search).length === 0);
            const handler = AngularRestHandler($http);
            return BoardState.load(url, handler, canPrefetch, (board) => {
                if (!board.accessibleInThisWorkspace(auth.getViewAs().code)) {
                    const workspaces = board.getWorkspaceCodes();
                    const suitableWorkspace = auth.findSuitableWorkspace(workspaces);
                    if (suitableWorkspace) {
                        auth.setViewAs(suitableWorkspace);
                    }
                    else {
                        auth.gotoHome();
                    }
                }
                const handlers = [];
                // First dress some filters for the URL's search.
                // A special extractor is used, because null value ids
                // are encoded as "null" strings in URLs. So to properly
                // compare them with value id's, we need to extract the
                // later as "null"...
                const searchX = function (x) {
                    return x.id === null ? 'null' : x.id;
                };
                const filters = Filters.dress(search, {
                    extractor: searchX,
                    dimensions: board.dimensions,
                }, true);
                // 1. apply filters from url
                if (!filters.isEmpty()) {
                    handlers.push((b) => {
                        // Now take the board filters and merge our search filters.
                        // The board filters extractor will be used at the end, given
                        // `merge` implementation...
                        const merged = b.filters.merge(filters);
                        // Apply those merged filters now.
                        return b.setFilters(merged);
                    });
                }
                // 2. apply search from url
                if (search._q) {
                    handlers.push((b) => b.withSearch(search._q));
                }
                // 3. apply pageSize from url
                if (search._pageSize) {
                    handlers.push((b) => b.withPageSize(parseInt(search._pageSize)));
                }
                // 3. always hide decks on mobile
                if (klaroUi.isMobile()) {
                    handlers.push((b) => b.withDecksState(false));
                }
                // 4. apply auth for permission
                handlers.push((b) => {
                    return b.usedBy(auth.getUser(), auth.getViewAs());
                });
                // 5. auto sync board as of user preference or per url param
                handlers.push(async (b) => {
                    const presenterMode = search._presenterMode || search._p;
                    const syncMode = presenterMode ? 'realTime' : auth.getUser().syncMode;
                    const mode = BoardSyncModes[syncMode];
                    return mode ? withMode(b, mode) : b;
                });
                if (mode === 'dashboard') {
                    handlers.push((b) => b.forDashboard('created'));
                }
                if (callback) {
                    handlers.push(callback);
                }
                const p = handlers.reduce((boardPromise, handler) => {
                    return boardPromise.then((aBoard) => handler(aBoard));
                }, Promise.resolve(board));
                return p.then(b => {
                    MyPinnedStories.fetch(b, false);
                    return b;
                });
            }, mode);
        }
        catch (ex) {
            // eslint-disable-next-line no-console
            console.log('Board loading failed', ex);
            throw (ex);
        }
    };
}

angular
    .module('klaro.v1')
    .config(['$httpProvider', function ($httpProvider) {
        $httpProvider.interceptors.push('authInterceptor');
        $httpProvider.interceptors.push('syncInterceptor');
    }])
    .config(['gravatarServiceProvider', function (gravatarServiceProvider) {
        gravatarServiceProvider.defaults = {
            size: 60,
            'default': 'mm',
        };
        gravatarServiceProvider.secure = true;
    }])
    .config(['$uibTooltipProvider', 'detectIt', function ($uibTooltipProvider, detectIt) {
        const opts = {
            placement: 'bottom',
            popupDelay: 500,
            appendToBody: true,
        };
        if (detectIt.primaryInput === 'touch') {
            opts.trigger = 'dontTrigger';
        }
        $uibTooltipProvider.options(opts);
    }])
    .config(['tmhDynamicLocaleProvider', function (tmhDynamicLocaleProvider) {
        tmhDynamicLocaleProvider.localeLocationPattern('/angular-locales/angular-locale_{{locale}}.js');
    }])
    .config(['LightboxProvider', function (LightboxProvider) {
        LightboxProvider.templateUrl = '@/core/story/attachments-gallery.html';
    }])
    .config(['timeAgoSettings', function (timeAgoSettings) {
        timeAgoSettings.refreshMillis = 60 * 1000;
    }])
    .config(['$compileProvider', function ($compileProvider) {
        $compileProvider.debugInfoEnabled(process.env.NODE_ENV !== 'production');
    }]);

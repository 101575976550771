// TODO refactor #treeshaking
import { Casino } from '@klaro/corejs/state';
const { foldTopCard, foldCards, unfoldOne, unfoldAll } = Casino;
angular
    .module('klaro')
    .directive('storyCasino', storyCasino);
function storyCasino($rootScope, hotkeys, ArrayUtils, dimensionValueEditModal, navigation, ActionsForHelper) {
    return {
        restrict: 'E',
        template: require('@/core/story/storyCasino.html'),
        scope: {
            'onClick': '&',
            'canWrite': '=',
        },
        require: '^board',
        link: function (scope, _elm, _attrs, boardCtrl) {
            scope.boardCtrl = boardCtrl;
            // Casino mode
            let casinoView;
            scope.casinoDimension = null;
            function setCasinoDimension() {
                const atCasino = boardCtrl.getDimensionsAt('displayBy');
                if (atCasino.length >= 1) {
                    scope.casinoDimension = atCasino[0];
                }
                else {
                    scope.boardCtrl = boardCtrl;
                    scope.configure = {};
                    scope.dimensions = boardCtrl.getRelevantDimensionsForAnchor('displayBy');
                    scope.casinoDimension = null;
                }
            }
            boardCtrl.$watch('board', setCasinoDimension, scope);
            scope.configureCasino = function (dimension) {
                return boardCtrl.mutate((b) => {
                    return b.addNewDimension(dimension).setDimensionsAt('displayBy', [dimension]);
                });
            };
            scope.setDisplayBy = function () {
                boardCtrl.setDimensionsAt('displayBy', [scope.configure.dimension]);
            };
            function computeCasino() {
                casinoView = boardCtrl.getBoardState().casinoView;
                if (!casinoView) {
                    return;
                }
                const globalContext = boardCtrl.getGlobalContext();
                scope.configure = {};
                scope.hasSummary = casinoView.hasNonTrivialSummary();
                scope.piles = casinoView.getAssignedStories(globalContext);
                scope.toDeal = casinoView.getUnassignedStories(globalContext);
                scope.storiesToDeal = casinoView.getStoriesToDeal(globalContext);
                scope.storiesToDecide = casinoView.getStoriesToDecide(globalContext);
                scope.allStoriesSelectedByPile = casinoView.getCardsDecks(globalContext).reduce((acc, cur) => {
                    const columnStoriesIds = cur.stories.map(s => s.id);
                    acc[cur.dimensionValue.id] = ArrayUtils.isSubset(columnStoriesIds, boardCtrl.selectedStoriesIds());
                    return acc;
                }, {});
                scope.casinoDimension = casinoView.getDisplayByDimension();
                scope._canCreateValue = boardCtrl.canWrite() && $rootScope.isAdmin && scope.casinoDimension && scope.casinoDimension.tagging;
                scope.piles.forEach((pile, index) => {
                    hotkeys.bindTo(scope)
                        .add({
                        combo: `alt+${scope.shortcutFor(index)}`,
                        callback: function (event) {
                            event.stopPropagation();
                            event.preventDefault();
                            const card = casinoView.getTopCard();
                            if (card) {
                                boardCtrl.moveStoryTo(card, pile.dimension, pile.dimensionValue);
                            }
                        },
                    });
                });
            }
            boardCtrl.$watch('board', computeCasino, scope);
            scope.$watch('casinoDimension', computeCasino, scope);
            scope.actionsFor = function (pile) {
                if (!pile.actions) {
                    pile.actions = ActionsForHelper.actionsForCasinoCell(boardCtrl, pile);
                }
                return pile.actions;
            };
            scope.foldTopCard = function () {
                boardCtrl.applyStateAction((s) => foldTopCard(s));
            };
            scope.unfoldAll = function () {
                boardCtrl.applyStateAction((s) => unfoldAll(s));
            };
            scope.unfoldOne = function () {
                boardCtrl.applyStateAction((s) => unfoldOne(s));
            };
            scope.foldCards = function (storyIds) {
                boardCtrl.resetAllCasinoCards(storyIds, (newState) => {
                    boardCtrl.applyStateAction(() => foldCards(newState, storyIds));
                });
            };
            scope.toggleAllStoriesSelected = function (event, dimensionValueId, stories) {
                // From actions context menu
                if (Array.isArray(dimensionValueId)) {
                    stories = dimensionValueId[1];
                    dimensionValueId = dimensionValueId[0];
                    scope.allStoriesSelectedByPile[dimensionValueId] = !scope.allStoriesSelectedByPile[dimensionValueId];
                }
                if (scope.allStoriesSelectedByPile[dimensionValueId]) {
                    boardCtrl.selectSome(stories);
                }
                else {
                    boardCtrl.unselectSome(stories);
                }
            };
            scope.canIWrite = function () {
                return scope.canWrite;
            };
            scope.canCreateValue = function () {
                return scope._canCreateValue;
            };
            scope.withNewDimensionValue = function (dimensionValue) {
                boardCtrl.addDimensionValue(scope.casinoDimension, dimensionValue);
            };
            hotkeys.bindTo(scope)
                .add({
                combo: 'down',
                description: 'Fold current card',
                callback: () => scope.foldTopCard(),
            })
                .add({
                combo: 'up',
                description: 'Unfold current card',
                callback: () => scope.unfoldOne(),
            });
            scope.shortcutFor = function (index) {
                return String.fromCharCode(97 + index);
            };
            scope.stackedEmptyDescription = function () {
                return $rootScope.ctrlKeyIsPressed ? '(up / down)' : 'Drag cards here to sort them later';
            };
        },
    };
}

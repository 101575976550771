angular
    .module('klaro')
    .service('Project', Project);
function Project($rootScope, $q, KernelConstants, projectRest, navigation) {
    let _refreshing = false;
    /**
     * Returns a promise that resolves to the current project.
     */
    function get() {
        const p = KernelConstants.project;
        if (p) {
            const deferred = $q.defer();
            deferred.resolve(p);
            return deferred.promise;
        }
        else {
            return refresh();
        }
    }
    /**
     * Returns the current project without a promise. Result
     * can be null.
     */
    function getSync() {
        return KernelConstants.project;
    }
    function refresh() {
        if (!_refreshing) {
            _refreshing = projectRest.get()
                .then((p) => {
                KernelConstants.project = p;
                _refreshing = false;
                return p;
            })
                .catch((err) => {
                KernelConstants.project = undefined;
                _refreshing = false;
                navigation.failed(err);
            });
        }
        return _refreshing;
    }
    function onEvent(event, args) {
        if (args && args.project) {
            KernelConstants.project = args.project;
        }
        else {
            KernelConstants.project = undefined;
            refresh();
        }
    }
    $rootScope.$on('klaro.user.changed', onEvent);
    $rootScope.$on('klaro.project.changed', onEvent);
    $rootScope.$on('klaro.application.reload', onEvent);
    return {
        get: get,
        getSync: getSync,
    };
}

angular
    .module('klaro.kernel')
    .service('KernelLoadRobinhqWidget', KernelLoadRobinhqWidget);
/**
 * This service injects robinhq's script if robinhq is enabled in env vars
 * or user wants to have it.
 */
function KernelLoadRobinhqWidget($log, KernelConstants) {
    const lazyLoadRobinhqWidget = async (widgetApiKey) => {
        if (KernelConstants.messengerLoaded) {
            return;
        }
        (function (d, script) {
            script = d.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.onload = setWidgetState;
            script.src = `https://selfservice.robinhq.com/external/robin/${widgetApiKey}.js`;
            d.getElementsByTagName('head')[0].appendChild(script);
        }(document));
        KernelConstants.messengerLoaded = true;
    };
    function setWidgetState() {
        if (!KernelConstants.hasMessenger) {
            return;
        }
        lazyLoadRobinhqWidget(process.env.KLARO_ROBINHQ_APPID);
    }
    function init() {
        $log.info('Loading CM Chatbot ....');
        KernelConstants.hasMessenger = !!process.env.KLARO_ROBINHQ_APPID;
        KernelConstants.messengerLoaded = false;
        setWidgetState();
    }
    function listen($rootScope) {
    }
    return {
        init: init,
        listen: listen,
    };
}

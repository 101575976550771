import { DimensionList } from '@klaro/corejs/views';
angular
    .module('klaro')
    .controller('DimensionsListController', DimensionsListController);
function DimensionsListController($scope, $rootScope, $location, dimensionRest, dimensions, dimensionEditModal, navigation, Title, ArrayUtils, events) {
    Title.setCurrentPage('Manage dimensions');
    $rootScope.view = 'dimensions';
    $scope.search = {
        text: ($location.search() || {}).q,
    };
    $scope.options = {
        showDisabled: false,
    };
    function reload() {
        dimensionRest.list().then((allDimensions) => {
            dimensions = allDimensions;
            refresh();
        });
    }
    $scope.reload = reload;
    function refresh() {
        $scope.list = new DimensionList({
            showDisabled: $scope.options.showDisabled,
            searchText: $scope.search.text,
            dimensions: dimensions,
        }).withAutoCollapse();
        $scope.kindDimension = dimensions.find(d => d.datatype === 'Kind');
    }
    $scope.$watchGroup(['search.text', 'options.showDisabled'], refresh);
    $scope.toggleSectionCollapse = function (section) {
        $scope.list = $scope.list.withSectionCollapseToggled(section);
    };
    $scope.dimensionChanged = function (dimension) {
        dimensionRest.getOne(dimension).then((dim) => {
            dimensions = ArrayUtils.replace(dimensions, (d) => {
                return d.code == dim.code;
            }, dim);
            refresh();
        });
    };
    ///
    $scope.newDimension = function ($event) {
        $event.preventDefault();
        $event.stopPropagation();
        openDimensionModal(dimensionRest.newOne());
    };
    $scope.editDimension = function (dimension) {
        if (!$rootScope.isAdmin) {
            return;
        }
        openDimensionModal(dimension);
    };
    function openDimensionModal(dimension) {
        dimensionEditModal
            .open(dimension, dimensions)
            .result
            .then(reload);
    }
    events.listen($scope, 'klaro.viewAs.changed', reload);
}

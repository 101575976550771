import { Story } from '@klaro/corejs/model';
angular
    .module('klaro')
    .directive('dimensionMultiselect', dimensionMultiselect);
/**
 * This directive implements a selector for a given dimension.
 *
 * It receives a dimension and makes the mapping select widget.
 */
function dimensionMultiselect(Config, dimensionRest, dimensionValueEditModal, navigation) {
    return {
        restrict: 'E',
        template: require('@/core/dimension/dimensionMultiselect.html'),
        scope: {
            dimension: '=',
            isRequired: '=',
            dimensions: '=?',
            storyData: '=?',
            board: '=?',
            disabled: '=?',
            allowNew: '=',
            threeState: '=?',
            onValueCreated: '&',
        },
        require: '^ngModel',
        link: function (scope, elm, attrs, ngModelController) {
            elm.addClass('dimension-multiselect');
            // Search handling -------------------------------------------------------
            scope.search = null;
            function resetSearch() {
                scope.search = null;
            }
            // Editing = whether the user currently sees all options -----------------
            scope.editing = false;
            scope.editOptions = function () {
                elm.find('.more-options').addClass('edited');
                scope.editing = !scope.editing;
                resetSearch();
            };
            scope.editingButtonLabel = function () {
                return (scope.editing) ? 'Close' : 'See all';
            };
            // Internal/External handling --------------------------------------------
            // valueList: internal representation of the widget, a sorted list
            // of available dimension values (selected first)
            scope.valueList = [];
            function readInputModel() {
                const input = ngModelController.$viewValue || [];
                if (input && input.selected && input.unselected) {
                    return input;
                }
                else {
                    return {
                        selected: Array.isArray(input) ? input : Array.of(input),
                        unselected: [],
                    };
                }
            }
            function stateFor(input, value) {
                if (input.selected.indexOf(value.id) !== -1) {
                    return 'selected';
                }
                else if (input.unselected.indexOf(value.id) !== -1) {
                    return 'unselected';
                }
                else {
                    return scope.threeState ? 'neutral' : 'unselected';
                }
            }
            // Computes the internal representation from the dimension values.
            function synchronizeInternalModel() {
                const input = readInputModel();
                let story = undefined;
                if (scope.storyData && scope.dimensions) {
                    story = Story.dress(scope.storyData, { dimensions: scope.dimensions });
                }
                const dim = scope.dimension.withoutDeprecatedValues(story);
                let values = dim.getValues(false)
                    .map((value) => {
                    return {
                        id: value.id,
                        label: value.label,
                        ordering: value.ordering,
                        state: stateFor(input, value),
                        clicked: false,
                    };
                });
                if (values.length > Config.multiselectMaxOptionsDisplay) {
                    values = values.sort((v1, v2) => {
                        if (v1.state !== v2.state) {
                            return v1.state === 'selected' ? -1 : 1;
                        }
                        else {
                            return v1.ordering - v2.ordering;
                        }
                    });
                }
                scope.valueList = values;
                resetSearch();
            }
            ngModelController.$render = synchronizeInternalModel;
            function selectedValues() {
                return scope.valueList.filter((v) => v.state === 'selected');
            }
            function unselectedValues() {
                return scope.valueList.filter((v) => v.state === 'unselected');
            }
            // Synchronize ngModel from selected values
            function synchronizeExternalModel() {
                const selected = selectedValues().map(v => v.id);
                const requiredOk = !scope.isRequired || selected.length > 0;
                let viewValue = null;
                if (scope.threeState) {
                    viewValue = {
                        selected: selected,
                        unselected: unselectedValues().map(v => v.id),
                    };
                }
                else {
                    viewValue = selected;
                }
                ngModelController.$setValidity('required', requiredOk);
                ngModelController.$setViewValue(viewValue);
            }
            elm.ready(() => {
                synchronizeExternalModel();
            });
            // Handling of value selection -------------------------------------------
            const sm2 = {
                'selected': 'unselected',
                'unselected': 'selected',
            };
            const sm3 = {
                'selected': 'unselected',
                'unselected': 'neutral',
                'neutral': 'selected',
            };
            scope.toggleSelected = function (value) {
                if (scope.disabled) {
                    return;
                }
                const sm = scope.threeState ? sm3 : sm2;
                value.state = sm[value.state];
                value.clicked = true;
                synchronizeExternalModel();
            };
            // Handling of "Add value" scenario --------------------------------------
            scope.addValue = function ($event) {
                if (scope.disabled) {
                    return;
                }
                $event.preventDefault();
                $event.stopPropagation();
                if (scope.dimension.datatype === 'StoryBinaryLink') {
                    const defaults = {};
                    const kindDimension = scope.board.getKindDimension();
                    scope.parentKind = Array.isArray(scope.dimension.datatypeOptions.kind) ? scope.dimension.datatypeOptions.kind : [scope.dimension.datatypeOptions.kind];
                    const validDimensionsValues = {
                        [kindDimension.code]: scope.parentKind,
                    };
                    if (scope.parentKind.length === 1) {
                        defaults[kindDimension.code] = scope.parentKind[0];
                    }
                    scope.board
                        .openNewStoryModal(defaults, false, false, validDimensionsValues)
                        .then((newStory) => {
                        const dimensionValue = {
                            id: newStory.identifier,
                            label: newStory.title,
                            ordering: newStory.identifier,
                            color: null,
                            semantics: null,
                            description: newStory.specification,
                            deprecated: false,
                        };
                        dimensionValueAdded(dimensionValue);
                    });
                }
                else {
                    dimensionValueEditModal
                        .open(scope.dimension, dimensionRest.newValueData(scope.dimension))
                        .result
                        .then((evt) => {
                        if (!evt.dimensionValue) {
                            return;
                        }
                        dimensionValueAdded(evt.dimensionValue);
                    });
                }
            };
            function dimensionValueAdded(value) {
                // We push internally, to get a chance to select the value
                scope.valueList.push({
                    id: value.id,
                    label: value.label,
                    state: 'selected',
                    clicked: true,
                });
                // synchronize the mode
                synchronizeExternalModel();
                // let the external world know, to update the dimension itself
                scope.onValueCreated({
                    dimension: scope.dimension,
                    value: value,
                });
            }
            scope.showAddValue = function () {
                return !scope.disabled && scope.allowNew && (!scope.moreOptions() || (scope.moreOptions() && scope.editing));
            };
            // Handling of "more options" and label ----------------------------------
            scope.moreOptions = function () {
                return scope.valueList.length > Config.multiselectMaxOptionsDisplay;
            };
            scope.someOptionSelected = function () {
                return selectedValues().length > 0;
            };
            scope.outOf = function () {
                const selected = selectedValues();
                return `${selected.length} of ${scope.valueList.length} option${(selected.length > 1) ? 's' : ''} selected`;
            };
            // Handling of maximum values shown
            scope.maxValuesShownLimit = 50;
        },
    };
}

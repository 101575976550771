// BABEL: Required for babel-7 & corejs polyfills
import 'core-js/stable';
import 'regenerator-runtime/runtime';
// \BABEL
require('./vendors');
import './index.scss';
angular
    .module('templates', []);
const requireAll = (r) => r.keys().forEach(r);
// Load the klaro.kernel module, with dependencies
requireAll(require.context('./kernel', true, /\.(js|ts)$/));
// Load the klaro module, with dependencies
// then directives, controllers, etc.
require('./klaro.js');
// Load the v1
require('./v1.js');
// Load the whiteboard
require('./whiteboard.js');
// Boot now
require('./boot.js');

angular
    .module('klaro.kernel')
    .constant('KernelAuthTokenKey', 'KLARO_AUTH_TOKEN_KEY')
    .service('KernelAuth', KernelAuth);
/**
 * This service encapsulates the kernel logic for authentication and related
 * user (/auth/me).
 *
 * It is aimed at being `init`ed *before* starting the application, since the
 * initialization process refreshes any token saved on local storage (as a help
 * to implement persistent login).
 *
 * This Kernel API can the be used to implement higher level authentication
 * mechanisms in the app: login/logout, etc.
 */
function KernelAuth($q, $http, $log, KernelAuthTokenKey, KernelConstants) {
    function getSession() {
        return KernelConstants.session;
    }
    function setSession(session) {
        KernelConstants.session = session;
        return session;
    }
    /**
     * Returns the auth token to inject on backend Api requests
     */
    function getToken() {
        return getSession().authToken;
    }
    /**
     * Returns the HTTP headers to use to call a backend service with
     * the (current) authorization token. (that is, if no token is provided,
     * the current token is used).
     */
    function getAuthorizationHeaders(token) {
        if (!token) {
            token = getToken();
        }
        if (!token) {
            return {};
        }
        return {
            'Authorization': `${token.token_type} ${token.access_token}`,
        };
    }
    /**
     * Similar to getAuthorizationHeaders, but wrapped inside an options
     * used on the $http service. */
    function getAuthorizationOptions(token) {
        return { headers: getAuthorizationHeaders(token) };
    }
    /**
     * Updates user information.
     *
     * This method erases any information about the current user in the
     * kernel itself, and should be used with care. It is provided as a way
     * to let the kernel know that the user or her profile changed.
     */
    function setUser(user) {
        const s = setSession(getSession().withUser(user));
        return s.user;
    }
    /** Sets the workspace to use for viewAs feature. */
    function setViewAs(workspace) {
        const s = setSession(getSession().withViewAs(workspace));
        return s.viewAs;
    }
    /** Resets the the workspace to the default user's one. */
    function resetViewAs() {
        const s = setSession(getSession().withDefaultViewAs());
        return s.viewAs;
    }
    /**
     * Loads and installs the user corresponding to the currently installed
     * token. Returns the user on success. Failure to be handled by the caller.
     */
    function loadCurrentUser(detached) {
        $log.debug('Loading current user...');
        if (detached) {
            const s = setSession(getSession().withUser({
                workspaces: [],
                isMember: false,
            }));
            return Promise.resolve(s.user);
        }
        else {
            return $http
                .get('/api/auth/me', getAuthorizationOptions())
                .then((response) => {
                const s = setSession(getSession().withUser(response.data));
                return s.user;
            });
        }
    }
    /**
     * Reset the entire authentication state to initial values.
     */
    function reset() {
        setSession(getSession().disconnected());
        loadCurrentUser();
    }
    /**
     * Returns a Promise that initializes the module, reloading and
     * refreshing any token found on local storage.
     */
    function init(detached = false) {
        $log.info('Reloading authentication token & user...');
        function cleanOutdatedToken() {
            window.localStorage.removeItem(KernelAuthTokenKey);
        }
        // Finds the token to use to get started. If a token is injected in URL,
        // it takes the priority, otherwise any token saved on in local storage.
        // Otherwise, we return nothing.
        function findToken() {
            let token = null;
            // We no longer support token in localStorage
            // and clean it during a couple of months...
            cleanOutdatedToken();
            // Look at the url
            const url = new URL(window.location);
            token = url.searchParams.get('authToken');
            if (token) {
                return {
                    token_type: 'OAuth2',
                    access_token: token,
                };
            }
        }
        const errorHandler = function (err) {
            if (err && err.status === 402) {
                window.location = '/blocked.html';
            }
            deferred.resolve();
        };
        const deferred = $q.defer();
        const token = findToken();
        if (token) {
            $log.info('Token found.');
            setSession(getSession().withAuthToken(token));
        }
        loadCurrentUser(detached)
            .then(deferred.resolve)
            .catch(errorHandler);
        return deferred.promise;
    }
    // This is the service API
    return {
        getToken: getToken,
        getAuthorizationHeaders: getAuthorizationHeaders,
        getAuthorizationOptions: getAuthorizationOptions,
        setUser: setUser,
        setViewAs: setViewAs,
        resetViewAs: resetViewAs,
        reset: reset,
        init: init,
    };
}

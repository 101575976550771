angular
    .module('klaro')
    .service('robust', robust);
function robust(MarkdownIt) {
    return {
        noop: noop,
        message: message,
    };
    function noop() {
    }
    function message(err, markdownIt) {
        let error = err == null ? 'An unexpected error occured, is the network on?' :
            typeof (err) == 'string' ? err :
                err.description ? err.description :
                    err.data && err.data.description ? err.data.description :
                        err.data && err.data.message ? err.data.message :
                            err.status && err.status >= 500 ? 'We are sorry ! If you want to help us to fix this, please report this error and describe what happened before it happens.' :
                                err.status && err.status >= 400 ? err.statusText :
                                    'We are sorry ! If you want to help us to fix this, please report this error and describe what happened before it happens.';
        if (error === null || error === undefined || error.length == 0) {
            error = 'We are sorry ! If you want to help us to fix this, please report this error and describe what happened before it happens.';
        }
        if (markdownIt || markdownIt === undefined) {
            return MarkdownIt.render(error);
        }
        else {
            return error;
        }
    }
}

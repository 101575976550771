import Integer from './Integer';
import StoriesPatch from '../StoriesPatch';
import ArrayUtils from '../../utils/ArrayUtils';
class CustomOrder extends Integer {
    get category() {
        return 'system';
    }
    canBeEditedAsCardDimension() {
        return false;
    }
    getEnsureStoriesPatch(stories, story, index) {
        const isAt = stories.findIndex((s) => s.id == story.id);
        const reordered = ArrayUtils.moveAt(stories, isAt, index);
        return new StoriesPatch(reordered.map((s, i) => {
            const oldIndex = s[this.code];
            return oldIndex === i ? undefined : { id: s.id, [this.code]: i };
        }).filter(Boolean));
    }
}
export default CustomOrder;

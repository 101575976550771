/**
 * This class is responsible of dealing with dimension's install process.
 * It determines which dimensions can be installed and the options according to their datatype
 */
import { DataObject, ArrayUtils } from '../utils/';
import Factory from './Dimensions/Factory';
class Installer extends DataObject {
    static dress(raw) {
        if (raw.constructor && raw.constructor == this) {
            return raw;
        }
        return new Installer(raw);
    }
    canBeUsedAsDateBoundary() {
        return !!Factory.DateBoundaryTypes[this.datatype];
    }
    orderByGroup(installers) {
        return ArrayUtils.groupBy(installers, 'group');
    }
}
export default Installer;
